const constant = {
  // api: "http://localhost:8400/api/",
  // url: "http://localhost:8400/",

  // api: "https://api5.peascom.ma/api/",
  // url: "https://api5.peascom.ma/",

  // SupportApi: "http://localhost:8000/api/",
  // SupportUrl: "http://localhost:8000/",

  SupportApi: "https://api.peascom.ma/api/",
  SupportUrl: "https://api.peascom.ma/",

  //  api: "https://psm-api.peascom.ma/api/",
  //  url: "https://psm-api.peascom.ma/",

  // api: "https://depot-api.goodgroupe.com/api/",
  // url: "https://depot-api.goodgroupe.com/",

  // api: "https://api.app.goodgroupe.com/api/",
  // url: "https://api.app.goodgroupe.com/",

  api: "https://api.oujdafood.ma/api/",
  url: "https://api.oujdafood.ma/",
};
export default constant;
