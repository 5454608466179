import Vue from "vue";
import ConfirmDialog from "../components/cards/VueConfirmDialog.vue";

const confirm = {
    install(Vue, options) {
        Vue.prototype.$confirm = async function (options) {
            return new Promise((resolve, reject) => {
                const confirmDialog = new Vue(ConfirmDialog).$mount()
                document.body.appendChild(confirmDialog.$el)
                confirmDialog.show = true

                confirmDialog.callback = options.callback // Pass the callback function as a prop

                confirmDialog.$props.callback = options.callback // Pass the callback function as a prop
        

                confirmDialog.title = options.title || "Confirm Action";
                confirmDialog.cancelButtonText = options.cancelButtonText;
                confirmDialog.confirmButtonText = options.confirmButtonText;
                confirmDialog.message = options.message;
                confirmDialog.button = options.button || {
                    no: "Cancel",
                    yes: "Confirm",
                };
            });
        };
    },
};

export default confirm;
