import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "orders";
// initial state
const state = () => ({
  all: [],
  orders: [],
  items: [],
  orderSupplierItems: [],
  order: {},
  supplier_orders: [],
  supplierOrder: {},
});

// getters
const getters = {
  getAll: (state) => state.all,
  getOrderSuppliers: (state) => state.supplier_orders,
  getItems: (state) => state.items,

  getOrderSupplierItems: (state) => state.orderSupplierItems,
  getOrder: (state) => state.order,
  getSupplierOrder: (state) => state.supplierOrder,

  getOrders: (state) => {
    return state.orders;
  },

  getSupplierOrders: (state) => {
    return state.supplier_orders;
  },
  totalPriceTTCItems: (state) => {
    return state.orderSupplierItems.reduce(
      (acc, val) => acc + val.quantity * val.priceTTC,
      0
    );
  },

  totalQuantityItems: (state) => {
    return state.orderSupplierItems.reduce((acc, val) => acc + val.quantity, 0);
  },
  numberItems: (state) => {
    return state.orderSupplierItems.length;
  },

  // order supplier
  getTotalPriceTTCOrderSupplierItems: (state) => {
    return state.orderSupplierItems.reduce(
      (acc, val) => acc +  val.totalPriceTTC,
      0
    );
  },

  getTotalQuantityOrderSupplierItems: (state) => {
    return state.orderSupplierItems.reduce((acc, val) => acc + val.quantity, 0);
  },
 
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let orders = response.data;
      commit("setOrders", orders);
    });
  },

  async getSupplierOrders({ commit }) {
    await axios.get(api + "_quantities_suppliers").then((response) => {
      let orders = response.data;
      commit("setSupplierOrders", orders);
    });
  },

  async fetchSupplierOrders({ commit }) {
    await axios.get(api + "_suppliers").then((response) => {
      let orders = response.data;
      commit("setSupplierOrders", orders);
    });
  },

  async getAllOujdaFood({ commit }) {
    await axios.get(api + "_oujdafood").then((response) => {
      let orders = response.data;
      commit("setOrders", orders);
    });
  },

  async getAllItems({ commit }) {
    await axios.get(api + "_items").then((response) => {
      commit("setItems", response.data);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let order = response.data;
      commit("setOrder", order);
    });
  },

  async showSupplierOrder({ commit }, reference) {
    await axios.get(api + "_suppliers/" + reference).then((response) => {
      let order = response.data;
      commit("SET_SUPPLIER_ORDER", order);
    });
  },

  async printSupplierOrder({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_suppliers_print_order/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Commande.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async showOujdaFood({ commit }, reference) {
    await axios.get(api + "_oujdafood/" + reference).then((response) => {
      let order = response.data;
      commit("setOrder", order);
    });
  },
  async getCustomerOrdersUnpaid({ commit }, customer_reference) {
    await axios.get(api + "_unpaid/" + customer_reference).then((response) => {
      let orders = response.data;
      commit("setOrders", orders);
    });
  },

  async storeItem({ commit, dispatch }, data) {
    await axios.post(api + "_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("setItems", response.data.data);
      }
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "orders-index" });
      }
    });
  },

  async storeSupplierOrder({ commit, dispatch }, data) {
    await axios.post(api + "_suppliers", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({ name: "orders-suppliers-index" });
      }
    });
  },

  async updateSupplierOrder({ commit, dispatch }, data) {
    await axios
      .put(api + "_suppliers/" + data.reference, data)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });
        if (status == "success") {
          router.push({ name: "orders-suppliers-index" });
        }
      });
  },

  async sendByEmailSupplierOrder({ dispatch }, reference) {
    dispatch(
      "notification/storeDownloading",
      { status: "success", message: "downloading..." },
      { root: true }
    );
    await axios
      .get(api + "_suppliers_send_by_email/" + reference)
      .then((response) => {
        let status = response.data.status;
        let message = response.data.message;

        dispatch("notification/store", { status, message }, { root: true });
      });
  },
  async update({ commit }, data) {
    await axios.put(api + "/" + data.reference, data.order).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let order = response.data.data;
      this._vm.$toasted.show(message, {
        type: status,
      });

      if (status == "success") {
        commit("setOrder", order);
        router.push({
          name: "profile-order",
          params: {
            reference: order.reference,
          },
        });
      }
    });
  },

  async getOrderSupplierItem({ commit, dispatch }) {
    await axios.get(api + "_suppliers_items").then((response) => {
      commit("SET_ORDER_SUPPLIER_ITEMS", response.data);
    });
  },
  async storeOrderSupplierItem({ commit, dispatch }, data) {
    await axios.post(api + "_suppliers_items", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("SET_ORDER_SUPPLIER_ITEMS", response.data.data);
      }
    });
  },
  async destroyOrderSupplierItem({ commit, dispatch }, id) {
    await axios.delete(api + "_suppliers_items/" + id).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        commit("SET_ORDER_SUPPLIER_ITEMS", response.data.data);
      }
    });
  },

  //
  async printPosPage({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_pos_page/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Facture.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printPurchaseOrderPage({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_purchase_order_page/" + reference, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Facture.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printManyPurchaseOrderPage({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(
          api + "_print_many_purchase_order_page",
          { selections: data },
          {
            responseType: "blob", // Set the response type to 'blob' to handle binary data
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Facture.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  setOrders(state, orders) {
    state.all = orders;
    state.orders = orders;
  },
  setSupplierOrders(state, orders) {
    state.supplier_orders = orders;
  },

  setItems(state, items) {
    state.items = items;
  },
  SET_ORDER_SUPPLIER_ITEMS(state, items) {
    state.orderSupplierItems = items;
  },
  setOrder(state, order) {
    state.order = order;
  },
  SET_SUPPLIER_ORDER(state, supplier_order) {
    state.supplierOrder = supplier_order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
