var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex align-items-center mt-0"},[_c('div',{staticClass:"col-xl-auto col-lg-auto col-md-auto col-auto"},[_c('span',[_c('i',{staticClass:"bi bi-logo bi-kanban-fill",style:({
          fontSize: '2rem !important',
        })})])]),_c('div',{staticClass:"col-xl-auto col-lg-auto col-md-4 col-1 d-xl-flex d-lg-flex d-md-flex d-none"},[_c('SearchVue')],1),_c('div',{staticClass:"col-xl col-lg col-md col-4 d-xl-flex d-lg-flex d-md-flex d-none"},[_c('NewsTickerVue')],1),_c('div',{staticClass:"col-xl-auto col-lg-auto col-md-auto col justify-content-end bprder border-primary d-flex align-items-center"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('button',{staticClass:"btn d-flex align-items-center px-1 py-2 text-warning rounded",on:{"click":function($event){_vm.logout_display ? (_vm.logout_display = false) : (_vm.logout_display = true)}}},[_vm._m(3),_c('span',{},[_vm._v(" "+_vm._s(_vm.authData.name)+" ")])]),_c('button',{staticClass:"btn btn-danger col-auto py-2",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Déconnecter ")])]),_c('div',{staticClass:"col-xl-auto col-lg-auto col-md-auto col-12"},[_c('AlertVue',{staticClass:"border"})],1),(_vm.logout_display)?_c('div',{staticClass:"position-absolute p-3 bg-white rounded shadow align-items-end",style:({
      width: '200px',
      height: '100px',
      position: 'absolute',
      top: '4.3em',
      right: '10px',
      zIndex: 1000,
    })},[_c('button',{staticClass:"btn btn-danger col-auto px-3 py-2",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Déconnecter ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"bi bi-question-circle fs-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"bi bi-gear fs-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"bi bi-grid-3x3-gap-fill fs-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"bi bi-person-circle fs-3"})])
}]

export { render, staticRenderFns }