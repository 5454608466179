import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "reports";
// initial state
const state = () => ({
  all: [],
  sales: [],
  items: [],
  sale: {},
  report: [],
  reportPyments: [],
  currentPage: 1,
  perPage: 10,
  totalItems: 0,
  errors: [],
  statisticsReport: [],

  creditBalances: [],
  discounts: [],
  employeeReport:[],
});

// getters
const getters = {
  getStatisticsReport: (state) => state.statisticsReport,
  getReport: (state) => state.report,

  getEmployeeReport: (state) => state.employeeReport,

  
  getReportPayments: (state) => state.reportPyments,
  getReportCreditBalances: (state) => state.creditBalances,
  getReportDiscounts: (state) => state.discounts,
  getTotalReportPayments: (state) =>
    state.reportPyments.reduce((acc, val) => acc + parseFloat(val.amount), 0),

  // Customer Report
  getTotalQuantity: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalQuantity), 0),
  getTotalPriceTTC: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.totalPriceTTC), 0),
  getTotalPaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountPaid), 0),
  getTotalUnpaid: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.amountUnpaid), 0),
  getTotalDiscount: (state) =>
    state.report.reduce((acc, val) => acc + parseFloat(val.discount), 0),
};

// actions
const actions = {
  async reportCustomer({ commit }, data) {
    await axios.post(api + "_customer_report", data).then((response) => {
      let report = response.data.sales;
      let payments = response.data.payments;
      let discounts = response.data.discounts;
      let creditBalances = response.data.creditBalances;
      commit("setReport", report);
      commit("SET_REPORT_PAYMENTS", payments);
      commit("SET_REPORT_DISCOUNTS", discounts);
      commit("SET_REPORT_CREDIT_BALANCES", creditBalances);
    });
  },

  async fetchReportEmployee({ commit }, data) {
    await axios.post(api + "_employee", data).then((response) => {
      let report = response.data;
      commit("SET_EMPLOYEE_REPORT", report);
    });
  },

  async sendByEmailReportEmployee({ dispatch }, data) {
    dispatch(
      "notification/storeDownloading",
      { status: "success", message: "downloading..." },
      { root: true }
    );
    await axios.post(api + "_employee_send_by_email", data).then((response) => {
      
      let status = response.data.status;
      let message = response.data.message;

      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async printReportCustomer({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(api + "_customer_report_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Client.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }

    // await axios.post(api + "_customer_report_print", data).then((response) => {
    //   let report = response.data;
    //   commit("setReport", report);
    // });
  },

  async printGeneralReportCustomer({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(api + "_customer_general_report_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Generale Client.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }

    // await axios.post(api + "_customer_report_print", data).then((response) => {
    //   let report = response.data;
    //   commit("setReport", report);
    // });
  },

  async printGeneralReportEmployee({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        { status: "success", message: "downloading..." },
        { root: true }
      );
      const response = await axios
        .post(api + "_employee_general_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rapport Generale Employee.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  setReport(state, report) {
    state.report = report;
  },

  SET_EMPLOYEE_REPORT(state, report) {
    state.employeeReport = report;
  },

  SET_REPORT_PAYMENTS(state, data) {
    state.reportPyments = data;
  },
  SET_REPORT_CREDIT_BALANCES(state, data) {
    state.creditBalances = data;
  },
  SET_REPORT_DISCOUNTS(state, data) {
    state.discounts = data;
  },
  SET_STATISTICS_REPORT(state, data) {
    state.statisticsReport = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
