<template>
  <div>
    <VueConfirmDialog></VueConfirmDialog>
    <InternetDetectionVue></InternetDetectionVue>
    <DownloadingVue></DownloadingVue>
    <div class="s-layout m-0 p-0">
      <!-- Sidebar -->
      <!-- <DashboardSidebarVue></DashboardSidebarVue> -->
      <!-- Header -->
      <div
        class="s-layout__header  position-fixed p-0 m-0"
      >
        <div class=" bg-primary text-white m-0 p-0 w-100">
          <HeaderVue />
        </div>
        <div
          class=" m-0 mt-0 p-0 d-xl-inline d-lg-inline d-md-inline d-none"
        >
          <DashboardNavBarVue class="bg-primary"></DashboardNavBarVue>
        </div>
      </div>
      <!-- Content -->
      <main class="s-layout__content ">
        <div class="s-layout__page-title">
          <!-- <i class="bi bi-chevron-right"></i> {{ $route.meta.title }} -->
        </div>
        <!-- <AlertVue class="border position-absolute right-0 top-0"></AlertVue> -->
        <!-- <hr /> -->
        <router-view />
        <br />
        <br />
        <br />
      </main>
    </div>
    <br />
    <div
      class="row d-xl-none d-lg-none d-md-none d-flex fixed fixed-bottom bg-white shadow bottom-0 top-auto"
    >
      <div class="col-4 text-center p-2">
        <router-link :to="{ name: 'mobile-home-index' }" class="btn">
          <i
            class="bi bi-house-door-fill fs-1"
            style="font-size: 25px !important"
          ></i>
        </router-link>
      </div>
      <div class="col-4 text-center p-2">
        <router-link :to="{ name: 'mobile-home-index' }" class="btn">
          <i
            class="bi bi-house-door-fill fs-1"
            style="font-size: 25px !important"
          ></i>
        </router-link>
      </div>
      <div class="col-4 text-center p-2">
        <router-link :to="{ name: 'mobile-home-index' }" class="btn">
          <i
            class="bi bi-house-door-fill fs-1"
            style="font-size: 25px !important"
          ></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import VueConfirmDialog from "../components/cards/VueConfirmDialog.vue";
import DashboardSidebarVue from "../components/sidebars/DashboardSidebar.vue";
import DashboardNavBarVue from "../components/navbars/DashboardNavbar.vue";
import InternetDetectionVue from "../views/notification/InternetDetection.vue";
import DownloadingVue from "../views/notification/Downloading.vue";
import CreateModalVue from "../components/modals/purchases/items/CreateModal.vue";
import HeaderVue from "../components/headers/Header.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    DashboardSidebarVue,
    DashboardNavBarVue,
    InternetDetectionVue,
    DownloadingVue,
    CreateModalVue,
    HeaderVue,
    VueConfirmDialog
  },
  data() {
    return {
      display_sidebar: false,
      logout_display: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  mounted() {
    // this.getWindowWidth();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      // this.$router.push({ name: "login" });
    },

    getWindowWidth() {
      const pageWidth = document.documentElement.clientWidth;
      console.log("Page Width (Vue):", pageWidth, "pixels");
      return pageWidth;
      // You can store this value in a data property if needed.
    },
  },
};
</script>
