<template>
  <div class="confirm-dialog" v-if="show" >
    
    <div class="overlay" @click="cancel"></div>
    <div class="confirm-dialog-modal card h-100">
      
      <div class="h-85">
        <h4>{{ title }}</h4> 
        <p>{{ message }}</p> 
      </div>
      <div class="buttons h-15">
        <button class="btn btn-secondary" @click="cancel">{{ button.no }}</button>
        <button class="btn btn-primary" @click="confirm">{{ button.yes }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirm Action'
    },
    message: {
      type: String,
      default: 'Are you sure you want to proceed?'
    },
    show: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      default: () => { }
    },
    button: {
      type: Object,
      default: () => ({ yes: 'Oui', no: 'Non' })
    }
  },
  data() {
    return {

    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.show = false;
    },

    confirm() {
      this.callback(true); 
      this.show = false;
    }
  }
}
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100hv !important;
  z-index: 10000 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.confirm-dialog-modal {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px !important;
  height: 300px !important;
  margin: 100px !important;
  z-index: 100011111 !important;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #ccc;
  color: #333;
}

.btn-primary {
  background-color: #337ab7;
  color: #fff;
}
</style>