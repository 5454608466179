<!-- <template>
  <div class="position-absolute w-100">
    <div class="d-flex justify-content-start overflow-x-auto py-xl-0 py-3">
    
      <div
        v-for="(navbar, index) in filteredNavbarData"
        :key="index++"
        class="w-auto"
      >
        <router-link
          :to="{ name: navbar.name }"
          :class="$route.name.includes(navbar.slug) ? 'active' : ''"
          class="s-navbar__nav-link py-xl-1 px-xl-2"
        >
          <i :class="`${navbar.icon}`"></i>
          <div>
            {{ navbar.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import navbar from "../../data/navbar/index.json";
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
      navbarData: navbar,
    };
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),

    filteredNavbarData() {
      if (this.authData.role != null) {
        if (this.authData.role.fullName != null) {
          if (this.authData.role.fullName == "Admin")
            return this.navbarData.filter((p) => p.is_admin == true);

          if (this.authData.role.fullName == "Caissier")
            return this.navbarData.filter((p) => p.is_cashier == true);

          if (this.authData.role.fullName == "Magasinier")
            return this.navbarData.filter((p) => p.is_storekeeper == true);

            if (this.authData.role.fullName == "Agent d'atelier automobile")
            return this.navbarData.filter((p) => p.is_car_workshop_agent == true);
        }
      }

      // return this.items.filter(
      //   (item) => item.section_reference == this.section_reference
      // );
    },
  },
};
</script>
<style scoped>
.active {
  border-bottom: 1px solid #ffffff;
  border-top: none;
  font-weight: 400 !important;
  background: #bfc9dd !important;
  /* color: #f4f4f4 !important; */
  border-radius: 3px !important;
}

.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo { 
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style> -->


<template>
  <div class="position-absolute w-100 shadow">
    <div class="d-flex justify-content-start overflow-x-auto py-xl-1 py-3 ">
      <div
        v-for="(navbar, index) in filteredNavbarData"
        :key="index"
        class="d-flex w-auto"
      >
        <button
          v-if="navbar.dropdown"
          class="btn s-navbar__nav-link py-xl-1 px-xl-2 dropdown-toggle text-white"
          :class="$route.name.includes(navbar.slug) ? 'active' : ''"
          @click="toggleDropdown(index)"
          >
          <i :class="`${navbar.icon}`"></i>
          <div >
            {{ navbar.title }}
          </div>
        </button>
        <router-link v-else
          :to="{ name: navbar.name }"
          :class="$route.name.includes(navbar.slug) ? 'active' : ''"
          class="s-navbar__nav-link py-xl-1 px-xl-2 text-white"
        >
          <i :class="`${navbar.icon}`"></i>
          
          <div >
            {{ navbar.title }}
          </div>
        </router-link>
        
        <ul
          v-if="navbar.dropdown"
          class="dropdown-menu border-0 rounded shadow mt-5"
          :class="{ show: navbar.dropdownOpen }"
        >
          <li
            v-for="(dropdownItem, subIndex) in navbar.dropdown"
            :key="subIndex"
          >
            <button
              class="dropdown-item "
              @click="handleDropdownClick(index, dropdownItem.name)"
            >
              {{ dropdownItem.title }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import navbar from "../../data/navbar/index.json";

export default {
  data() {
    return {
      navbarData: this.initializeNavbarData(navbar),
    };
  },
  methods: {
    initializeNavbarData(navbarData) {
      return navbarData.map(item => {
        item.dropdownOpen = false; // Initialize the dropdownOpen property for each item
        return item;
      });
    },
    toggleDropdown(index) {
      this.navbarData.forEach((item, i) => {
        if (i !== index) {
          item.dropdownOpen = false;
        }
      });
      this.$set(this.navbarData, index, {
        ...this.navbarData[index],
        dropdownOpen: !this.navbarData[index].dropdownOpen
      });
    },
    async handleDropdownClick(index, name) {
      this.closeDropdown(index);
      this.$router.push({ name });
    },
    closeDropdown(index) {
      this.$set(this.navbarData, index, {
        ...this.navbarData[index],
        dropdownOpen: false
      });
    }
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
    filteredNavbarData() {
      if (this.authData.role != null) {
        if (this.authData.role.fullName != null) {
          if (this.authData.role.fullName == "Admin")
            return this.navbarData.filter((p) => p.is_admin == true);

          if (this.authData.role.fullName == "Caissier")
            return this.navbarData.filter((p) => p.is_cashier == true);

          if (this.authData.role.fullName == "Magasinier")
            return this.navbarData.filter((p) => p.is_storekeeper == true);

          if (this.authData.role.fullName == "Agent d'atelier automobile")
            return this.navbarData.filter((p) => p.is_car_workshop_agent == true);
        }
      }
      return [];
    }
  }
};

</script>

<style scoped>
.s-navbar__nav-link {
  display: flex;
  align-items: center;
}
.dropdown-menu {
  display: none;
}
.dropdown-menu.show {
  display: block;
}
/* .active {
  border-bottom: 1px solid #ffffff;
  border-top: none;
  font-weight: 400 !important;
  background: #bfc9dd !important;
  border-radius: 3px !important;
} */

.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
